import { createBrowserRouter } from 'react-router-dom';
import { QUIERO_INVERTIR } from "../../Config/Router/paths";

import Navbar from "../../Components/Navbar";
import Footer from "../../Components/general/Footer";

import Landing from "../../Pages/Landing";
import QuieroInvertir from "../../Pages/QuieroInvertir";

export const router = createBrowserRouter([
    {
        path: '/',
        children: [
            {
                index: true,
                element: (
                    <div>
                        <Navbar />
                        <Landing />
                        <Footer />
                    </div>
                )
            },
            {
                path: QUIERO_INVERTIR,
                element: (
                    <div>
                        <Navbar />
                        <QuieroInvertir />
                        <Footer />
                    </div>
                )
            }
        ]
    }
]);